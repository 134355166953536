@use "styles/abstract/variables" as *;

.heroSection {
  width: 100%;
  height: 90vh;
  position: relative;
}

.sliders {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sliderArrows {
  position: absolute;
  z-index: 10;
  transform: translateY(-250%);
  display: flex;
  right: 10%;
  bottom: 10%;
  gap: 20px;

  .slideRight,
  .slideLeft {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    width: 30px;
    height: 20px;
  }
}

.slideRight {
  right: 0;
  background-image: (url("/images/RightArrow.svg"));
}

.slideLeft {
  left: 0;
  background-image: (url("/images/LeftArrow.svg"));
}

.scrollDownWrapper {
  position: absolute;
  bottom: 10%;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
}

.scrollDown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transform: rotate(270deg);
  cursor: pointer;
}

.lineScroll {
  cursor: pointer;
  height: 2px;
  width: 20px;
  background-image: url("/images/line.svg");
}

.scrollTxt {
  font-family: $secondary-font-family;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
}
