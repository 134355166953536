@use "styles/abstract/variables" as *;
@use "styles/customStyles/antDesign" as *;

.selectLang {
  @extend %ant-select-search;
}

.langOptions {
  font-family: $primary-font-family;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
}
