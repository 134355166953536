@use "styles/abstract/variables" as *;

.container {
  margin: auto;
  background: #0d0d0d;

  .primaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    padding: 10px 20px;
    margin: auto;
  }
  .primaryHeaderText {
    font-family: $primary-font-family;
    color: #ffffff;
  }
}
