@use "styles/abstract/variables" as *;

.sales {
  max-width: $page-width;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 100px;

  .title {
    font-family: $secondary-font-family;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: #000;
  }

  .productsDisplay {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
  }
}
