@use "styles/abstract/variables" as *;

.footerContainer {
  background: #0d0d0d;
  margin-top: 100px;
}

.footerContent {
  max-width: $page-width;
  padding: 60px 30px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .footerTitleEl,
  .footerTextEl {
    color: #fff;
    font-family: $secondary-font-family;
  }

  .footerTextEl {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
}

.footerText {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .footerLogo {
    width: 85px;
    height: 85px;
  }
  .footerTextEl {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
  }
}

.footerSocials {
  display: flex;
  gap: 10px;

  .footerSocialIcon {
    width: 30px;
    height: 30px;
  }
}

.footerLinksDiv {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.footerEmailBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;

  .footerTextEl {
    text-align: end;
  }
}

.footerForm {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #ffffff;
}

.formInput {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  .footerInputEl,
  .footerFormBtn {
    background: #0d0d0d;
    border: none;
    outline: none;
    color: #fff;
    font-family: $secondary-font-family;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
  .footerInputEl {
    font-weight: 300;
    width: 70%;
    ::placeholder {
      color: #fff;
    }
  }
  .footerFormBtn {
    cursor: pointer;
    font-weight: 500;
  }
}

@media screen and (max-width: 870px) {
  .footerContent {
    padding: 40px 30px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
  }

  .footerText {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .footerLogo,
  .footerSocials {
    margin: auto;
  }

  .footerLinksDiv {
    width: 100%;
    margin-bottom: 30px;
  }

  .footerEmailBox {
    width: 75%;
  }

  .footerForm,
  .footerEmailBox {
    gap: 30px;
  }
}
