%ant-select-search {
  :global {
    .ant-select-arrow {
      color: #fff;
    }
  }
}

:global {
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    border-bottom-width: 0;
  }
  .ant-select .ant-select-selector {
    color: #fff;
  }
}
