@use "styles/abstract/variables" as *;

.navContainer {
  z-index: 1000;
  position: absolute;
  right: 0;
  left: 0;

  .navBar {
    max-width: $page-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    margin: auto;
  }

  .navLogo {
    width: 85px;
    height: 85px;
    .navLogoIcon {
      width: 100%;
      height: 100%;
    }
  }

  .navLinks {
    display: flex;
    gap: 35px;
    justify-content: space-between;
    align-items: center;
  }

  .navLink {
    font-family: $secondary-font-family;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.16px;
    color: #ffffff;
    cursor: pointer;
  }

  .navIcons {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    .navIcon {
      display: flex;
    }
  }

  .navCart {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .navCartItems {
    background: #d9d9d9;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: white;
    text-align: center;

    font-family: $primary-font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #0d0d0d;
  }

  .navMenuContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
    text-align: end;
    background: #0d0d0dbf;
    padding: 16px;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .navMenu {
    display: none;
    margin-left: 50px;
    position: relative;
  }

  @media screen and (max-width: 1100px) {
    .navLinks {
      display: none;
    }

    .navMenu {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 768px) {
    .navIcons {
      width: 40%;
    }

    .navIcon {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 600px) {
    .navIcons {
      width: 50%;
      gap: 10px;
    }

    .navMenu {
      margin-left: 0;
    }

    .navMenu {
      margin-left: 20px;
    }
  }
}
