@use "styles/abstract/variables" as *;

.productCard {
  min-height: 400px;

  .imageSliderWrapper {
    height: 255px;
    width: 255px;

    .sliders {
      img {
        border-radius: 15px;
      }
    }
  }

  .productDetails {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    .productText {
      margin: 0;
      color: #151515;
      font-family: $secondary-font-family;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
    }

    .productOption {
      display: none;
    }
  }

  .arrows {
    position: absolute;
    z-index: 1;
    transform: translateY(-250%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: all 0.2s ease-in-out;
    .rightVector,
    .leftVector {
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      width: 30px;
      height: 50px;
      background-color: #fff;
      position: relative;
      transition: all 0.2s ease-in-out;
    }
    .rightVector {
      left: 30px;
    }

    .leftVector {
      right: 30px;
    }
  }

  &:hover {
    .arrows {
      .rightVector {
        left: 0;
      }

      .leftVector {
        right: 0;
      }
    }

    .rightVector {
      background-image: (url("/images/right-vector.png"));
    }

    .leftVector {
      background-image: (url("/images/left-vector.png"));
    }

    .productDetails {
      position: relative;
      z-index: 10;
      font-family: $secondary-font-family;
      font-weight: 400;
      line-height: 150%;
      color: #151515;
      padding: 15px 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transform: translateY(-30px);
      width: 100%;

      .productText {
        font-size: 15px;
      }

      .productOption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .addProduct {
          font-family: $secondary-font-family;
          font-weight: 500;
          font-size: 10px;
          margin: 0;
        }

        .toggleView {
          cursor: pointer;
        }
      }
    }
  }
}
